import { Application } from "@hotwired/stimulus"

import "htmx.org"
import Carousel from "stimulus-carousel"

import { BillListNav, BillListSearch } from "../../apps/bills/static_src/bills/bill_list/bill_list"
import {
  Briefing,
  BriefingDatepicker,
  BriefingNav,
  BriefingToc,
  Briefings,
  BriefitemSummaries,

  BriefitemToolbar,
} from "../../apps/briefings/static_src/briefings/base/briefing"
import { Ticker } from "../../apps/editorials/static_src/editorials/components/editorials/briefing_ticker.js"
import { BriefItems } from "../../apps/editorials/static_src/editorials/components/editorials/briefitems.js"
import { Editorials } from "../../apps/editorials/static_src/editorials/components/editorials/editorials.js"
import { Searchfacet } from "../../apps/editorials/static_src/editorials/components/searchfacet/searchfacet"
import { WatchGroup } from "../../apps/keywords_alerts/static_src/keywords_alerts/main"
import { Alerts } from "../../apps/monitoring/static_src/monitoring/alerts"
import { PaywallDetails, PaywallLists } from "../../apps/paywall/static_src/paywall/main"
import { Captcha } from "../../apps/subscriptions/static_src/subscriptions/trial/captcha"
import { SmsResend } from "../../apps/subscriptions/static_src/subscriptions/trial/sms-resend"
import { SubscriptionsForm } from "../../apps/subscriptions/static_src/subscriptions/trial/subscriptionsform"
import { TranscriptSearch, TranscriptSearchNavigation, TranscriptVideo, Transcripts } from "../../apps/transcripts/static_src/transcripts/transcripts"
import { BackButton, Banner, ChipField, Clipboard, Confirm, Dialog, Dropdown, Facet, Panel, PhoneField, Previews, ReadMore, RichComponent, Select, ShowPassword, Showmore, Toaster, openDialog } from "../../apps/ui/static_src/ui/components"
import { UIKitButtons } from "../../apps/ui/static_src/ui/kit/buttons/main"
import { Footer, Header, Menu, ThemeSelect } from "../../apps/ui/static_src/ui/layout"
import { LanguageBanner } from "../../apps/ui/static_src/ui/layout/language_banner/language_banner.js"
import { CookiesConsent, Experiment, Tracking } from "../../apps/ui/static_src/ui/services/main"

window.stimulus ??= Application.start()
window.stimulus.register("carousel", Carousel)
window.stimulus.register("alerts", Alerts)
window.stimulus.register("back-button", BackButton)
window.stimulus.register("banner", Banner)
window.stimulus.register("bill-list-nav", BillListNav)
window.stimulus.register("bill-list-search", BillListSearch)
window.stimulus.register("briefings", Briefings)
window.stimulus.register("briefing", Briefing)
window.stimulus.register("briefing-nav", BriefingNav)
window.stimulus.register("briefing-toc", BriefingToc)
window.stimulus.register("briefing-datepicker", BriefingDatepicker)
window.stimulus.register("briefitems", BriefItems)
window.stimulus.register("briefitem-toolbar", BriefitemToolbar)
window.stimulus.register("briefitem-summaries", BriefitemSummaries)
window.stimulus.register("chipfield", ChipField)
window.stimulus.register("clipboard", Clipboard)
window.stimulus.register("confirm", Confirm)
window.stimulus.register("cookies-consent", CookiesConsent)
window.stimulus.register("dialog", Dialog)
window.stimulus.register("dropdown", Dropdown)
window.stimulus.register("editorials", Editorials)
window.stimulus.register("experiment", Experiment)
window.stimulus.register("facet", Facet)
window.stimulus.register("footer", Footer)
window.stimulus.register("header", Header)
window.stimulus.register("language-banner", LanguageBanner)
window.stimulus.register("menu", Menu)
window.stimulus.register("open-dialog", openDialog)
window.stimulus.register("theme-select", ThemeSelect)
window.stimulus.register("panel", Panel)
window.stimulus.register("paywall-lists", PaywallLists)
window.stimulus.register("paywall-details", PaywallDetails)
window.stimulus.register("phone-field", PhoneField)
window.stimulus.register("previews", Previews)
window.stimulus.register("readmore", ReadMore)
window.stimulus.register("rich-component", RichComponent)
window.stimulus.register("searchfacet", Searchfacet)
window.stimulus.register("select", Select)
window.stimulus.register("showmore", Showmore)
window.stimulus.register("show-password", ShowPassword)
window.stimulus.register("sms-resend", SmsResend)
window.stimulus.register("subscriptions-form", SubscriptionsForm)
window.stimulus.register("captcha", Captcha)
window.stimulus.register("ticker", Ticker)
window.stimulus.register("toaster", Toaster)
window.stimulus.register("tracking", Tracking)
window.stimulus.register("transcript-video", TranscriptVideo)
window.stimulus.register("transcripts", Transcripts)
window.stimulus.register("transcript-search", TranscriptSearch)
window.stimulus.register("transcript-search-navigation", TranscriptSearchNavigation)
window.stimulus.register("watchgroup", WatchGroup)
window.stimulus.register("ui-kit-buttons", UIKitButtons)
