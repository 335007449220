import { Controller } from "@hotwired/stimulus"

class LanguageBanner extends Controller {
  static values = {
    languageOptions: Object,
  }

  connect() {
    this.labelTarget = this.element.querySelector(".select__button-label")
    this.patchSelectLabel()
    this.bindLabelOverride()
  }

  patchSelectLabel() {
    const checked = this.element.querySelector("input[name='language']:checked")
    if (checked) {
      this.updateLabel(checked.value)
    }
  }

  bindLabelOverride() {
    const radios = this.element.querySelectorAll("input[name='language']")
    radios.forEach(radio => {
      radio.addEventListener("change", () => {
        this.updateLabel(radio.value)
      })
    })
  }

  updateLabel(value) {
    const labelMap = this.languageOptionsValue
    const prefix = value === "fr" ? "Langue :" : "Language:"
    const labelText = labelMap[value] || value
    if (this.labelTarget) {
      setTimeout(() => {
        this.labelTarget.textContent = `${prefix} ${labelText}`
      })
    }
  }
}

export { LanguageBanner }
